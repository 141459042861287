<template>
  <div class="post-form">
    <h2 class="title">编辑资料</h2>
    <el-form ref="form" :model="formData" :rules="rules" :inline="true">
      <div class="section">
        <h3 class="section-title">资源信息</h3>
        <div class="section-item">
          <el-form-item label=" " prop="resourceName">
            <el-input v-model="formData.resourceName" placeholder="请输入作品名称" size="mini" style="width: 600px" clearable
              maxlength="40"></el-input>
          </el-form-item>
          <br>
          <el-form-item label=" " prop="category">
            <el-select v-model="formData.category" size="mini" placeholder="请选择资源类型" clearable>
              <el-option v-for="(item, index) in categoryList" :key="index" :value="item.value"
                :label="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" " prop="typeId">
            <el-select v-model="formData.typeId" size="mini" placeholder="请选择资源风格" clearable>
              <el-option v-for="(item, index) in typesList" :key="index" :label="item.typeName"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">资源内容</h3>
        <div class="section-item">
          <el-form-item label=" " prop="tags">
            <el-input v-model="formData.tags" placeholder="标签（多个请以英文“,”逗号分隔开，如：个性化设计,宝藏资源）" size="mini"
              style="width: 600px" :maxlength="100" clearable></el-input>
          </el-form-item>
          <el-form-item label=" " prop="description">
            <el-input v-model="formData.description" placeholder="请简要的描述下资源的内容、用处、使用方法等信息" type="textarea" size="large"
              style="width: 600px" maxlength="1000" :rows="10" show-word-limit clearable></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">详细信息</h3>
        <div class="section-item">
          <el-form-item label=" " prop="richContent">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 500px; overflow-y: hidden;" v-model="formData.richContent"
              :defaultConfig="editorConfig" :mode="mode" @onCreated="onEditorCreated" />
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">资源展示 <span class="remark">注：不要再图片上放置商业推广信息（除案例信息外），不超过10张。</span></h3>
        <div class="section-item">
          <el-form-item label=" " prop="posters">
            <div class="files-list">
              <el-upload :action="baseUrl + '/uploader/uploadFile'" :accept="acceptImgType.join(',')"
                v-loading="postersLoading" :data="{ type: 'image' }" :show-file-list="false"
                :headers="{ [tokenKeyName]: token, system }" :before-upload="uploadBefore" :on-exceed="uploadExceed"
                :on-success="uploadSuccess" :file-list="postersFiles" :limit="10" drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">支持JPG/PNG格式<em></em></div>
              </el-upload>
              <div class="files-item" v-for="(item, index) in formData.posters" :key="index">
                <el-image :preview-teleported="true" fit="contain" :src="item && $store.state.assetsUrl + item"
                  :preview-src-list="formData.posters.map(v => $store.state.assetsUrl + v)" alt="">
                  <template #error>
                    <div class="image-slot">
                      <i class="el-icon-picture"></i>
                    </div>
                  </template>
                </el-image>
                <span class="icon" @click="handleRemove(index, formData)">
                  <i class="el-icon-close"></i>
                </span>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">资源封面 <span class="remark">注：不要再图片上放置商业推广信息（除案例信息外），不超过1张。</span></h3>
        <div class="section-item">
          <el-form-item label=" " prop="poster">
            <div class="files-list">
              <el-upload :action="baseUrl + '/uploader/uploadFile'" :accept="acceptImgType.join(',')"
                v-loading="posterLoading" :data="{ type: 'image', compress: 1 }" :show-file-list="false"
                :headers="{ [tokenKeyName]: token, system }" :before-upload="uploadPosterBefore"
                :on-exceed="uploadPosterExceed" :on-success="uploadPosterSuccess" :file-list="posterFile" :limit="1" drag>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">支持JPG/PNG格式<em></em></div>
              </el-upload>
              <div class="files-item" v-if="typeof formData.poster === 'string'">
                <el-image lazy :preview-teleported="true" fit="contain"
                  :src="formData.poster && $store.state.assetsUrl + formData.poster"
                  :preview-src-list="formData.poster && [$store.state.assetsUrl + formData.poster] || []" alt="">
                  <template #error>
                    <div class="image-slot">
                      <i class="el-icon-picture"></i>
                    </div>
                  </template>
                </el-image>
                <span class="icon" @click="handlePosterRemove(formData)">
                  <i class="el-icon-close"></i>
                </span>
              </div>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">资源上传 <span class="remark">注：资源文件大小不得超过200M</span></h3>
        <div class="section-item">
          <el-form-item label=" " prop="resourceType">
            <el-select size="mini" v-model="formData.resourceType" placeholder="上传文件类型" clearable>
              <el-option v-for="(item, index) in resourceTypeList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <br>
          <el-form-item label=" " prop="filePath">
            <el-upload :action="baseUrl + '/uploader/uploadFile'" v-loading="resourceFileLoading" :data="{ type: 'file' }"
              :headers="{ [tokenKeyName]: token, system }" :on-success="uploadResourceFileSuccess"
              :before-upload="beforeUploadResourceFile" :file-list="resourceFiles" :on-remove="removeResourceFile"
              :limit="1" drag>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">这里上传源文件<em></em></div>
            </el-upload>
          </el-form-item>
        </div>
      </div>
      <div class="section">
        <h3 class="section-title">资源协议</h3>
        <div class="section-item">
          <el-form-item label=" ">
            <el-checkbox v-model="isAgreePostProtocol">我同意</el-checkbox><span class="protocol-trigger"
              @click="showProtocol">资源发布协议</span>
          </el-form-item>
        </div>
      </div>
      <el-form-item v-if="!isView" class="btns">
        <el-button class="btn" size="mini" icon="el-icon-refresh" @click="resetForm">重置</el-button>
        <el-button class="btn" size="mini" type="primary" icon="el-icon-s-promotion" @click="submitForm">发布</el-button>
      </el-form-item>
    </el-form>

    <el-dialog class="resource-upload-protocol" :visible.sync="isProtocolModal" width="700px">
      <h2 class="protocol-title">资源上传协议</h2>
      <div class="protocol-body">
        <p class="paragraph paragraph-title">尊敬的用户：</p>
        <p class="paragraph">您好！</p>
        <p class="paragraph">我们对用户在系统中上传的资源关于查看、使用等相关信息，拟定如下协议：</p>
        <p class="paragraph">
          用户上传资源将视为捐赠予本网站的开发者“海口市龙华区抵御者网络科技”团队（以下简称“抵御者团队”），抵御者团队有权任意使用站内资源，抵御者团队有权将站内资源转移至其他网站，抵御者团队可以使用站内资源进行其他商业合作，网站及资源的解释权归抵御者团队所有。
        </p>
        <p class="paragraph">
          用户不可私自转载系统资源，如需转载请注明来源及出处，站内所有视频、音频、图片、文档、源码等项目信息仅供用户个人学习使用，请用户勿用于商业用途，若您发现权利被侵害，请截图保留证据并直接发送邮件至864823609@qq.com。我们将第一时间处理，感谢您的支持！
        </p>
        <p class="paragraph">如有疑问，可以通过首页下方联系方式与我们联系。</p>
      </div>
      <div class="protocol-author">
        <p><span>抵御者团队</span></p>
        <p><span>2023年5月18日</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css";
import { setOptions } from "../../utils";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { updateFile } from "../../api/common.js";
import { system, tokenKeyName } from "../../setting";

export default {
  name: "ResourcesPost",
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Editor, Toolbar
  },
  data() {
    return {
      typesList: [],
      postersFiles: [],
      resourceFiles: [],
      posterFile: [],
      isAgreePostProtocol: false, // 协议同意
      isProtocolModal: false, // 资源上传协议弹窗
      postersLoading: false,
      posterLoading: false,
      resourceFileLoading: false,

      editor: null,
      toolbarConfig: {},
      editorConfig: {
        placeholder: "您可以使用图文描述的形式将资源的详细内容展示给大家", MENU_CONF: {
          uploadImage: { customUpload: null },
          uploadVideo: { customUpload: null }
        }
      },
      mode: "default",
      tokenKeyName,
      system
    };
  },
  computed: {
    categoryList() {
      return setOptions("category_list");
    },
    resourceTypeList() {
      return setOptions("resource_type_list");
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
    acceptImgType() {
      return this.$store.state.acceptImgType;
    },
    token() {
      return this.$store.state.token;
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    onEditorCreated(editor) {
      this.editor = Object.seal(editor)
    },
    // 初始化editor
    initEditor() {
      this.editorConfig.MENU_CONF['uploadImage'] = {
        customUpload: async (file, insertFn) => {
          updateFile(file, { type: "editorImg", userId: this.userInfo && this.userInfo.id }).then((res) => {
            insertFn(this.$store.state.baseUrl + res.data[0], null, null);
          });
        },
      };
      this.editorConfig.MENU_CONF['uploadVideo'] = {
        customUpload: async (file, insertFn) => {
          updateFile(file, { type: "editorVideo", userId: this.userInfo && this.userInfo.id }).then((res) => {
            insertFn(this.$store.state.baseUrl + res.data[0], null, null);
          });
        },
      };
    },
    // 根据分类加载对应废品类型
    getTypesList(category, target = "typesList") {
      this.$get("types/typesByCategory", {
        category,
      }).then((res) => {
        this[target] = res.data || [];
      });
    },
    // 上传前
    uploadBefore() {
      this.postersLoading = true;
      return true;
    },
    // 超出数量的方法
    uploadExceed() {
      this.postersLoading = false;
      this.$message.warning("文件数量超出最大限制");
    },
    // 图片上传成功的回调函数
    uploadSuccess(res, file, fileList) {
      this.postersLoading = false;
      this.postersFiles = fileList;
      if (res.code == 200) {
        this.formData.posters.push(res.data);
      } else {
        this.$message.error(res.message);
        this.$nextTick(() => {
          this.postersFiles.splice(
            this.postersFiles.findIndex((v) => v.uid == file.uid),
            1
          );
        });
      }
    },
    // 删除图片
    handleRemove(index, target) {
      this.$confirm("确定删除此图片么？", "提示", {
        cancelButtonText: "否",
        confirmButtonText: "是",
        type: "warning",
      }).then(() => {
        target.posters.splice(index, 1);
        this.postersFiles.splice(index, 1);
      });
    },
    // 上传前
    uploadPosterBefore() {
      this.posterLoading = true;
      return true;
    },
    // 超出数量的方法
    uploadPosterExceed() {
      this.posterLoading = false;
      this.$message.warning("文件数量超出最大限制");
    },
    // 封面图上传成功的回调函数
    uploadPosterSuccess(res, file, fileList) {
      this.posterLoading = false;
      this.posterFile = fileList;
      if (res.code == 200) {
        this.formData.poster = res.data;
      } else {
        this.$message.error(res.message);
        this.$nextTick(() => {
          this.posterFile.splice(
            this.posterFile.findIndex((v) => v.uid == file.uid),
            1
          );
        });
      }
    },
    // 删除图片
    handlePosterRemove(target) {
      this.$confirm("确定删除此图片么？", "提示", {
        cancelButtonText: "否",
        confirmButtonText: "是",
        type: "warning",
      }).then(() => {
        target.poster = null;
        this.posterFile = [];
      });
    },
    // 上传前
    uploadResourceFileBefore() {
      this.resourceFileLoading = true;
      return true;
    },
    // 超出数量的方法
    uploadResourceFileExceed() {
      this.resourceFileLoading = false;
      this.$message.warning("文件数量超出最大限制");
    },
    // 图片上传成功的回调函数
    uploadResourceFileSuccess(res, file, fileList) {
      this.resourceFileLoading = false;
      this.resourceFiles = fileList;
      if (res.code == 200) {
        this.formData.filePath = res.data;
      } else {
        this.$message.error(res.message);
        this.$nextTick(() => {
          this.resourceFiles.splice(
            this.resourceFiles.findIndex((v) => v.uid == file.uid),
            1
          );
        });
      }
    },
    // 资源上传前，检测文件大小
    beforeUploadResourceFile(file) {
      console.log(file.size)
      let fileSize = file.size / 1024 / 1024; // 换算为M单位

      if (fileSize > 200) {
        this.$message.warning("上传资源文件大小不得超过200M");
        return false;
      }

      return true;
    },
    // 删除图片
    removeResourceFile(file, fileList) {
      this.formData.filePath = null;
      this.resourceFiles = fileList;
    },
    // 重置表单信息
    resetFields() {
      // 清空表单的数据项
      this.$emit("reset");
      this.$refs.form.resetFields();
      // 清空表单外的文件数据和列表数据
      this.postersFiles = [];
      this.posterFile = [];
      this.resourceFiles = [];
      this.typesList = [];
    },
    resetForm() {
      this.$confirm("是否确定重置表单", "提示", {
        type: "warning",
      }).then(() => {
        this.resetFields();
      });
    },
    generateImgInfo(fileInfo) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let img = new Image();
          img.src = e.target.result;
          img.onload = function () {
            console.log("图片抓取成功", this.naturalWidth, this.naturalHeight);
            resolve({
              url: fileInfo.response.data,
              naturalWidth: this.naturalWidth,
              naturalHeight: this.naturalHeight,
            });
          };

          img.onerror = function () {
            reject(null);
            console.log("图片抓取失败");
          };
        };
        reader.readAsDataURL(fileInfo.raw);
      });
    },
    submitForm() {
      if (!this.isAgreePostProtocol) return this.$message.warning("请选择同意资源上传协议后提交")
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 当富文本的纯文本内容是 空字符串 的时候，清空内容
          let richContentText = this.editor.getText();
          if (!(richContentText && richContentText.trim())) {
            this.formData.richContent = "";
          }

          // 当处于编辑状态时候，用户并为上传图片，那么poster则自动取原数据
          this.formData.poster = this.posterFile[0] && this.posterFile[0].raw ? await this.generateImgInfo(this.posterFile[0]).then(
            (data) => data
          ) : {
            url: this.formData.poster,
            naturalWidth: this.formData.naturalWidth,
            naturalHeight: this.formData.naturalHeight
          };

          // 如果当前资源没有被修改，那么直接取原文件名
          this.formData.fileName = this.resourceFiles[0] ? this.resourceFiles[0].name.substring(0, 50) : this.formData.fileName;

          this.$emit("submit");
        } else {
          this.$message.error("请将表单必填项填写完整");
        }
      });
    },

    /**
     * @name 展示资源上传协议
     */
    showProtocol() {
      this.isProtocolModal = true;
    }
  },
  created() {
    this.initEditor();
  },
  mounted() {
    // 编辑的情况下，初始化编辑数据
    if (this.formData.id) {
      // 初始化类型下拉选项
      this.getTypesList(this.formData.category);
      // 初始化假的资源数据;
      this.resourceFiles = [
        {
          name: this.formData.fileName,
          uid: Date.now(),
          url: this.formData.filePath
        }
      ]
      this.posterFile = [
        {
          name: Date.now(),
          uid: Date.now(),
          url: this.formData.poster
        }
      ]
      this.postersFiles = this.formData.posters.reduce((p, n) => p.concat({
        name: Date.now(),
        uid: Date.now(),
        url: n
      }), []);

    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  watch: {
    "formData.category"(newV) {
      if (newV) {
        this.getTypesList(newV);
      } else {
        this.typesList = [];
      }
      this.formData.typeId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.post-form {
  padding: 20px;
  max-width: 1200px;
  min-width: 800px;
  margin: 0 auto;

  /deep/.el-form-item__label,
  /deep/.el-radio__label,
  /deep/.el-checkbox__label {
    color: #c8c7cc;
  }
}
</style>


<style lang="scss" scoped>
// 统一表单样式
.title {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  font-weight: 500;
  padding: 10px 20px;
  background-color: #232529;
  border-radius: 4px;
  margin-bottom: 30px;
}

.section {
  margin-bottom: 30px;
  border-radius: 4px;
  background-color: #232529;
}

.section-title {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  font-weight: 500;
  padding: 10px 20px;
  border-bottom: 1px solid #000;

  .remark {
    color: #b5b5b5;
    margin-left: 30px;
  }
}

.section-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  color: #fff;

  .user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 40px;
  }

  .change-btn {
    padding: 0 30px;
    line-height: 24px;
    height: 24px;
  }
}

.section-item {

  padding: 20px 20px 0;
  color: #fff;
  font-size: 14px;

  .section-item-label {
    margin-right: 10px;
    display: inline-block;
    width: 80px;
  }

  .section-item-value {
    display: inline-block;
    width: calc(100% - 90px);
  }
}

.btns {
  .btn {
    width: 180px;
    padding: 0;
    height: 32px;
    line-height: 32px;
  }
}


// 个性化表单

.files-list {
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .files-item {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 120px;
    height: 120px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px;
    background-color: rgba(68, 174, 240, 0.4);
    border: 1px solid rgba(68, 174, 240, 0.4);
    transition: 0.3s;
    cursor: pointer;
    position: relative;

    .icon {
      text-align: center;
      width: 24px;
      height: 24px;
      line-height: 24px;
      vertical-align: top;
      display: none;
      font-size: 16px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px 0 4px 4px;

      i {
        color: #fff;
        display: inline-block;
      }

      &:hover {
        i {
          color: #e6a23c;
        }
      }
    }

    .el-image {
      width: 120px;
      height: 120px;
      vertical-align: top;
    }

    &:hover {
      .icon {
        display: inline-block;
      }
    }
  }
}

.protocol-trigger {
  cursor: pointer;

  &:hover {
    color: #4093ff;
  }
}

/deep/.el-upload-dragger {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .el-icon-upload {
    margin: 0 auto;
    font-size: 48px;
  }

  .el-upload__text {
    font-size: 12px;
    line-height: 24px;
  }
}

/deep/.resource-upload-protocol {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    max-height: 50vh;
    overflow: auto;
    padding: 20px;

    .protocol-title {
      text-align: center;
      line-height: 50px;
      margin-bottom: 20px;
    }

    .protocol-body {
      font-size: 16px;
      text-align: justify;
      line-height: 2em;

      .paragraph:not(.paragraph-title) {
        text-indent: 2em;
      }
    }

    .protocol-author {
      text-align: right;
      margin-top: 50px;
      font-size: 16px;

      span {
        display: inline-block;
        width: 200px;
        text-align: center;
        line-height: 2em;
      }
    }
  }
}

// 富文本样式
.editor {
  height: 100vh;
  width: 100%;
}
</style>