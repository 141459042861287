import { post } from "../utils/request"

// 上传文件
export const updateFile = (file, { type, userId }) => new Promise((resolve, reject) => {
    let params = new FormData();
    params.append("name", "file");
    params.append("file", file);
    params.append("userId", userId);
    params.append("type", type);

    post("/uploader/editor-upload", params).then(res => {
        resolve(res);
    }).catch(err => {
        reject(err)
    })

})